import React from "react"
import styled from "styled-components"

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafcfd;
  gap: 30px;
  padding: 60px 0;

  h2 {
    font-family: Qanelas;
    font-size: 36px;
    font-weight: 800;
    line-height: 1.2;

    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
  p {
    font-family: Qanelas;
    font-size: 25px;
    line-height: 39px;
    font-weight: 600;
    max-width: 830px;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`
