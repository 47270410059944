import React, { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import ScheduleCarousel from "src/components/common/ScheduleCarousel"

const NumberOfShowsToPull = 50
// const ShowApi = `https://playout.fubo.tv/schedule/v3/fbsn/en-US/upcoming/os8TKdVsV5cnYxb16U0I?count=${NumberOfShowsToPull}`;
// const OnNowApi = `https://playout.fubo.tv/schedule/v3/fbsn/en-US/now/os8TKdVsV5cnYxb16U0I`;
const ShowApi = `https://playout.fubo.tv/schedule/v3/fbsn/en-UX/upcoming/GKcxWWAIY8aN3b8U6qIA?count=${NumberOfShowsToPull}`
const OnNowApi = `https://playout.fubo.tv/schedule/v3/fbsn/en-UX/now/GKcxWWAIY8aN3b8U6qIA`

const ScheduleCarouselSection = () => {
  const [onNowShow, setOnNowShow] = useState()
  const [upcomingShows, setUpcomingShows] = useState([])

  useEffect(() => {
    axios
      .get(OnNowApi)
      .then(function(response) {
        if (response.data.currentAiring !== "undefined") {
          setOnNowShow(response.data.currentAiring)
        }
      })
      .catch(function(error) {
        console.log(error)
      })

    axios
      .get(ShowApi)
      .then(function(response) {
        if (response.data.upcomingAirings !== "undefined") {
          setUpcomingShows(response.data.upcomingAirings)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [])

  const combinedShowData = () => {
    if (upcomingShows) {
      if (onNowShow) {
        return [onNowShow].concat(upcomingShows)
      }
      return upcomingShows
    }
    return []
  }

  return (
    <ScheduleWrapper>
      {onNowShow && upcomingShows.length > 0 && (
        <>
          <ScheduleTitle>LIVE AND UPCOMING</ScheduleTitle>
          {combinedShowData() && (
            <ScheduleCarouselWrapper>
              <ScheduleCarousel showsArray={combinedShowData()} />
            </ScheduleCarouselWrapper>
          )}
        </>
      )}
    </ScheduleWrapper>
  )
}

export default ScheduleCarouselSection

const ScheduleWrapper = styled.div`
  padding: 50px 0 0;
  background: radial-gradient(
    circle at center center,
    rgba(40, 32, 185, 0.4) 50%,
    rgba(40, 32, 185, 0) 100%
  );
  background-color: #15101e;
`

const ScheduleTitle = styled.div`
  font-family: Qanelas-ExtraBold;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0.34px;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;

  @media screen and (min-width: 1101px) {
    text-align: left;
  }
`

const ScheduleCarouselWrapper = styled.div``
